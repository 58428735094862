import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./Play.css";
import { useLocation } from "react-router-dom";
import data from "../../data/data.js";
import Card from "../Card/Card.js";
import SmallCard from "../SmallCard/SmallCard.js";
import racket from "../../images/racket.webp";
import clear from "../../images/clear.svg";
import ContactPopup from "../Popup/ContactPopup.js";
import Form from "../Form/Form.js";

const Play = () => {
  const location = useLocation();
  const [playersData, setPlayersData] = useState(data);
  const [isChosen, setIsChosen] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isTeamReady, setIsTeamReady] = useState(false);

  function choose(card) {
    setPlayersData((prevData) =>
      prevData.map((player) =>
        player.id === card.id ? { ...player, isChosen: true } : player
      )
    );

    setIsChosen((prevIsChosen) => [card, ...prevIsChosen]);

    if (isChosen.length + 1 === 2) {
      setIsVisible(true);
    }
  }

  function deletePlayer(card) {
    setPlayersData((prevData) =>
      prevData.map((player) =>
        player.id === card.id ? { ...player, isChosen: false } : player
      )
    );
    setIsChosen((prevIsChosen) => {
      return prevIsChosen.filter((i) => i.id !== card.id);
    });
    setIsVisible(false);
  }

  function clearPlayers() {
    setIsChosen([]);
    setIsVisible(false);
    setPlayersData(data);
  }
  return (
    <section className="bet-section pad">
      {location.pathname === "/play-now" ? (
        <h1>Speel nu</h1>
      ) : (
        <h2>Speel nu</h2>
      )}
      <p className="t-14 bet-about__text">
        Kies je team in elke sport om 100 keer het inschrijfgeld te winnen. Het
        is zo simpel.
      </p>
      <div className="bet-play__body">
        <div className="bet-play__players">
          <p className="t-26 fw-900 ital upper bet-play__section-name">
            Lijst van tennisspelers
          </p>
          <div className="bet-play__grid bet-play__pc">
            {playersData.map((i) => (
              <Card data={i} key={i.id} fn={choose} isVisible={isVisible} />
            ))}
          </div>
          <Swiper
            spaceBetween={10}
            slidesPerView={1} // Один слайд с 4 элементами (2 строки по 2)
            className="bet-play__swiper"
            modules={[Pagination]}
            pagination={{ clickable: true }}
          >
            {Array.from({ length: Math.ceil(data.length / 4) }, (_, index) => {
              const slideData = playersData.slice(index * 4, index * 4 + 4);
              return (
                <SwiperSlide key={index}>
                  <div className="bet-play__grid">
                    {slideData.map((item) => (
                      <Card
                        data={item}
                        key={item.id}
                        fn={choose}
                        isVisible={isVisible}
                      />
                    ))}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="bet-play__players">
          <p className="t-26 fw-900 ital upper bet-play__section-name">
            Uw team
          </p>
          <div className="bet-play__chosen">
            {isChosen.length === 0 ? (
              <div className="bet-play__chosen-container">
                <p className="bet-play__chosen-text fw-900 ital upper text-center">
                  Geen Spelers Geselecteerd
                </p>
                <p className="t-14 text-center">
                  Klik op spelers aan de linkerkant om ze te selecteren.
                </p>
                <img
                  src={racket}
                  title="tennis racket"
                  alt="tennis racket"
                  width="175"
                  height="175"
                />
              </div>
            ) : (
              <>
                <div className="bet-play__chosen-top">
                  <p className="t-12">Spelers toegevoegd {isChosen.length}/2</p>
                  <button
                    onClick={clearPlayers}
                    className="bet-play__chosen-btn"
                  >
                    Wissen
                    <img
                      src={clear}
                      title="clear"
                      alt="clear"
                      width="8"
                      height="8"
                    />
                  </button>
                </div>

                {isChosen.map((i) => (
                  <SmallCard data={i} key={i.id} fn={deletePlayer} />
                ))}

                {isVisible && (
                  <button
                    onClick={() => setIsTeamReady(true)}
                    className="bet-play__play-btn fw-800 ital"
                  >
                    Speel nu
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isTeamReady && <Form fn={setIsTeamReady}/>}
    </section>
  );
};

export default Play;
