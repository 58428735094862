import React from "react";
import "./Popup.css";
import balls from "../../images/tennis-balls.webp";

const ContactPopup = ({ fn }) => {
  return (
    <div className="bet-popup">
      <div className="bet-form-popup__body">
        <img
          src={balls}
          title="tennis balls"
          alt="tennis balls"
          className="bet-form-popup__img"
          width="264"
          height="107"
        />
        <h2 className="text-center">Bedankt voor uw bericht</h2>
        <p className="t-14">Onze manager neemt snel contact met u op</p>
      </div>
    </div>
  );
};

export default ContactPopup;
