import React from "react";
import "./About.css";

import about1 from "../../images/about-1.webp";
import about2 from "../../images/about-2.webp";
import about3 from "../../images/about-3.webp";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  return (
    <section className="bet-section pad">
      {location.pathname === "/about-us" ? (
        <h1>Over ons</h1>
      ) : (
        <h2>Over ons</h2>
      )}

      <p className="bet-about__text t-14">
        Welkom bij Betroyal-NL, uw belangrijkste bestemming voor
        fantasy-sportliefhebbers. Of je nu een ervaren speler bent of net begint
        met fantasy sporten, we hebben alles wat je nodig hebt om je spel te
        verbeteren en je kansen om te winnen te maximaliseren.
      </p>
      <div className="bet-about__grid">
        <div className="bet-about__item">
          <img
            src={about1}
            title="Kies min of meer"
            alt="Kies min of meer"
            width="393"
            height="217"
            className="bet-about__img"
          />
          <p className="t-20 ital fw-900 upper">Kies min of meer</p>
          <p className="t-14">Over spelervoorspellingen</p>
        </div>
        <div className="bet-about__item">
          <img
            src={about2}
            title="Win echt geld"
            alt="Win echt geld"
            width="393"
            height="217"
            className="bet-about__img"
          />
          <p className="t-20 ital fw-900 upper">Win echt geld</p>
          <p className="t-14">Tot 100X</p>
        </div>
        <div className="bet-about__item">
          <img
            src={about3}
            title="Onmiddellijke uitbetaling"
            alt="Onmiddellijke uitbetaling"
            width="393"
            height="217"
            className="bet-about__img"
          />
          <p className="t-20 ital fw-900 upper">Onmiddellijke uitbetaling</p>
          <p className="t-14">Met Veilige Opnames</p>
        </div>
      </div>
    </section>
  );
};

export default About;
