import React from "react";

import { useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./News.css";
import news1 from "../../images/cast-1.webp";
import news2 from "../../images/cast-2.webp";
import news3 from "../../images/cast-3.webp";

const News = () => {
  const location = useLocation();
  return (
    <section className="bet-section pad">
      {location.pathname === "/news" ? (
        <h1>Voorspellingen</h1>
      ) : (
        <h2>Voorspellingen</h2>
      )}
      <p className="bet-about__text t-14">
        Voorspellen en grote overwinningen behalen
      </p>
      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          749: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 14,
          },
        }}
        navigation
        pagination={{ clickable: true }}
        className="bet__swiper"
      >
        <SwiperSlide className="bet-about__item">
          <img
            src={news1}
            title="match 1"
            alt="match 1"
            width="393"
            height="217"
            className="bet-about__img bet-news__img"
          />
          <p className="t-12 fw-500">16. November 2024</p>
          <p className="t-14 ital fw-900 upper">
            Week 13 College Football Picks: prijs Picks Cheat Sheet voor CFB DFS
          </p>
          <p className="t-14">
            Vind de top CFB picks voor zaterdag 9 November, voor Prijskeuzes en
            CFB DFS. Voeg deze spelers toe aan je Line-ups en koken Ws deze
            week.
          </p>
        </SwiperSlide>
        <SwiperSlide className="bet-about__item">
          <img
            src={news2}
            title="match 2"
            alt="match 2"
            width="393"
            height="217"
            className="bet-about__img bet-news__img"
          />
          <p className="t-12 fw-500">21. November 2024</p>
          <p className="t-14 ital fw-900 upper">
            Beste Touchdown Scorer voorspellingen voor NFL Week 12
          </p>
          <p className="t-14">
            Bekijk de beste touchdown scorer voorspellingen voor de NFL-lijst
            van deze week, inclusief een QB, RB en te who zijn trending in de
            goede richting voor NFL DFS.
          </p>
        </SwiperSlide>
        <SwiperSlide className="bet-about__item">
          <img
            src={news3}
            title="match 3"
            alt="match 3"
            width="393"
            height="217"
            className="bet-about__img bet-news__img"
          />
          <p className="t-12 fw-500">22. November 2024</p>
          <p className="t-14 ital fw-900 upper">
            NBA DFS Spickzettel heute: Tipps & Vorhersagen für NBA Mittwoch
          </p>
          <p className="t-14">
            Vind de top NBA DFS picks en projecties voor de NBA Wednesday slate,
            plus de beste games om te richten voor fantasy Basketbal productie
            op prijs Picks.
          </p>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default News;
