import React from "react";
import "./Resp.css";

const Resp = () => {
  return (
    <main>
      <section className="bet-privacy bet-resp pad">
        <div className="bet-privacy__top">
          <div className="bet-privacy__top-left">
            <h1>Verantwoord spelen</h1>
            <p className="t-14">
              Net als veel andere verslavingen houdt dwangmatig gokken
              oncontroleerbare driften in die, als ze niet worden gecontroleerd,
              tot financiële moeilijkheden kunnen leiden. Iedereen kan worden
              blootgesteld aan dwangmatig gokken, ongeacht leeftijd, geslacht,
              ras of financiële status. Eenmaal gedetecteerd, kan dwangmatig
              spel met succes worden behandeld. Het belangrijkste punt is vroege
              detectie om nadelige en ernstige gevolgen te voorkomen. Als je het
              gevoel hebt dat je een dwangmatig spelprobleem hebt, raden we je
              ten zeerste aan om hulp te zoeken.
            </p>
          </div>
          <div className="bet-privacy__img"></div>
        </div>
        <div className="bet-privacy__bottom">
          <ul className="bet-privacy__list">
            <li className="t-14">
              Waarschuwingssignalen van dwangmatig gokken zijn:
            </li>
            <li className="t-14">
              Meer spelen om eerdere verliezen terug te verdienen of terug te
              verdienen.
            </li>
            <li className="t-14">
              Om tijd door te brengen met familie of op het werk om deel te
              nemen aan toernooien.
            </li>
            <li className="t-14">
              Een terugkerend onvermogen om deelname te stoppen of te
              controleren.
            </li>
            <li className="t-14">
              Geld lenen om deel te nemen aan of eerdere verliezen terug te
              betalen.
            </li>
            <li className="t-14">
              Verwaarlozing van je familie of jezelf als gevolg van overmatig
              gokken.
            </li>
            <li className="t-14">
              Liegen of de waarheid verbergen over tijd of geld verloren tijdens
              het spel.
            </li>
            <li className="t-14">
              Verkopen of verhypothekeren van persoonlijke eigendommen voor het
              spel.
            </li>
            <li className="t-14">
              Zich hopeloos, depressief of suïcidaal voelen.
            </li>
          </ul>
          <p className="t-14">
            Als jij of iemand die je kent compulsieve gokverslaving ervaart of
            kan ervaren, is het belangrijk om te onthouden dat hulp, Interventie
            en behandeling beschikbaar zijn.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Resp;
