import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="bet-popup-cookie">
        <form className="bet-popup__cookie-form pad">
          <div className="bet-popup-cookie__left">
            <p className="t-20 col-white upper fw-600">Cookie beleid</p>
            <p className="t-12 col-white">
              Wij gebruiken cookies om de functionaliteit van onze website te
              verbeteren en gepersonaliseerde inhoud te bieden. Door de website
              te blijven gebruiken, accepteert u het gebruik van cookies en ons
              beleid voor gegevensbeheer.
            </p>
          </div>
          <div className="bet-popup__btn-container">
            <p
              className="bet-popup__btn-cookie bet-popup__btn-agree ital fw-800"
              onClick={addPopupCookie}
            >
              Accepteren
            </p>
            <a href="/cookies" className="bet-popup__btn-cookie ital fw-800 col-white">
              Meer informatie
            </a>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
