import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="bet-privacy pad">
        <h1>Welkom op onze website!</h1>
        <p className="t-14">
          Om u de beste gebruikerservaring te bieden en het surfen op de website
          zo soepel mogelijk te laten verlopen, maken wij gebruik van cookies.
          In dit beleid leggen wij uit wat cookies zijn, hoe wij ze gebruiken en
          welke keuzes u heeft over ons gebruik ervan.
        </p>
        <h2>Wat zijn cookies?</h2>
        <p className="t-14">
          Cookies zijn kleine tekstbestanden die op uw apparaat (computer,
          smartphone, tablet) worden opgeslagen wanneer u een website bezoekt.
          Ze helpen websites uw acties en voorkeuren te onthouden (bijvoorbeeld
          taal, lettergrootte, gebruikersnaam), zodat u deze niet elke keer
          opnieuw hoeft in te voeren wanneer u een website bezoekt of van de ene
          pagina naar de andere gaat.
        </p>
        <h2>Hoe gebruiken wij cookies?</h2>
        <p className="t-20">
          Wij gebruiken cookies voor de volgende doeleinden:
        </p>
        <ul>
          <li className="t-14">
            *Zorg voor functionaliteit van de website: Cookies helpen de website
            goed te laten functioneren, bijvoorbeeld door u in staat te stellen
            in te loggen op uw account en uw winkelwagentje te gebruiken.
          </li>
          <li className="t-14">
            *Verbeterde gebruikerservaring: we gebruiken cookies om uw
            voorkeuren (bijvoorbeeld taal of regio) op te slaan en uw
            website-ervaring persoonlijker en handiger te maken.
          </li>
          <li className="t-14">
            * Analyse van websiteverkeer: we gebruiken cookies om anonieme
            informatie te verzamelen over hoe gebruikers onze website gebruiken.
            Deze gegevens helpen ons om de website te verbeteren en relevanter
            voor u te maken.
          </li>
          <li className="t-14">
            * Reclame en marketing: Wij kunnen cookies gebruiken om u reclame te
            tonen waarvan wij denken dat deze voor u interessant kan zijn. We
            gebruiken ook cookies om de effectiviteit van onze
            advertentiecampagnes te volgen.
          </li>
        </ul>
        <h2>Welke soorten cookies gebruiken wij?</h2>
        <p className="t-20">Wij gebruiken verschillende soorten cookies:</p>
        <ul>
          <li className="t-14">
            * Noodzakelijke cookies: Deze cookies zijn essentieel voor het goed
            functioneren van de website. Ze verzamelen geen informatie over u.
          </li>
          <li className="t-14">
            * Functionele cookies: Met deze cookies kan de website uw voorkeuren
            onthouden (bijvoorbeeld taal of regio) om uw ervaring op de website
            persoonlijker en gemakkelijker te maken.
          </li>
          <li className="t-14">
            * Analytische cookies: deze cookies verzamelen anonieme informatie
            over hoe gebruikers onze website gebruiken. Deze gegevens helpen ons
            om de website te verbeteren en relevanter voor u te maken.
          </li>
          <li className="t-14">
            * Marketingcookies: deze cookies worden gebruikt om u advertenties
            te tonen waarvan wij denken dat deze voor u interessant kunnen zijn.
          </li>
        </ul>
        <h2>Hoe kan ik cookies beheren?</h2>
        <p className="t-20">
          U kunt de cookies beheren die op onze website worden gebruikt.
        </p>
        <ul>
          <li className="t-14">
            *U kunt uw browserinstellingen op elk moment wijzigen om cookies te
            blokkeren.
          </li>
          <li className="t-14">
            *U kunt bestaande cookies van uw apparaat verwijderen.
          </li>
          <li className="t-14">
            *U kunt de beste advertentiecampagnes kiezen die u interesseren.
          </li>
        </ul>
        <h2>Meer informatie</h2>
        <p className="t-14">Meer informatie over cookies vindt u hier.</p>
        <h2>Neem contact met ons op</h2>
        <p className="t-14">
          Als u vragen heeft over ons cookiebeleid, kunt u contact met ons
          opnemen via betroyal-nl@gmail.com.
          <br></br>Belangrijk: Als u ervoor kiest om cookies te blokkeren,
          werken sommige functies van onze website mogelijk niet correct.
        </p>
        <h2>Bedankt voor het gebruik van onze website!</h2>
      </section>
    </main>
  );
};

export default Cookies;
