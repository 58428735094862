import pl1 from "../images/Sinner-Jannik.webp";
import pl2 from "../images/Zverev-Alexander.webp";
import pl3 from "../images/Alcaraz-Carlos.webp";
import pl4 from "../images/Fritz-Taylor.webp";
import pl5 from "../images/Medvedev-Daniil.webp";
import pl6 from "../images/Ruud-Casper.webp";
import pl7 from "../images/Djokovic-Novak.webp";
import pl8 from "../images/Rublev-Andrey.webp";
import pl9 from "../images/De-Minaur-Alex.webp";
import pl10 from "../images/Dimitrov-Grigor.webp";
import pl11 from "../images/Tsitsipas-Stefanos.webp";
import pl12 from "../images/Paul-Tommy.webp";
import pl13 from "../images/Rune-Holger.webp";
import pl14 from "../images/Humbert-Ugo.webp";
import pl15 from "../images/Draper-Jack.webp";
import pl16 from "../images/Hurkacz-Hubert.webp";
import pl17 from "../images/Musetti-Lorenzo.webp";
import pl18 from "../images/Tiafoe-Frances.webp";
import pl19 from "../images/Khachanov-Karen.webp";
import pl20 from "../images/Fils-Arthur.webp";
import pl21 from "../images/Shelton-Ben.webp";
import pl22 from "../images/Tabilo-Alejandro.webp";
import pl23 from "../images/Korda-Sebastian.webp";
import pl24 from "../images/Popyrin-Alexei.webp";

const data = [
  {
    id: 1,
    img: pl1,
    name: "Sinner Jannik",
    number: "11 830",
    country: "ITA",
    isChosen: false,
  },
  {
    id: 2,
    img: pl2,
    name: "Zverev Alexander",
    number: "7 915",
    country: "GER",
    isChosen: false,
  },
  {
    id: 3,
    img: pl3,
    name: "Alcaraz Carlos",
    number: "7 010",
    country: "ESP",
    isChosen: false,
  },
  {
    id: 4,
    img: pl4,
    name: "Fritz Taylor",
    number: "5 100",
    country: "USA",
    isChosen: false,
  },
  {
    id: 5,
    img: pl5,
    name: "Medvedev Daniil",
    number: "5 030",
    country: "RUS",
    isChosen: false,
  },
  {
    id: 6,
    img: pl6,
    name: "Ruud Casper",
    number: "4 255",
    country: "NOR",
    isChosen: false,
  },
  {
    id: 7,
    img: pl7,
    name: "Djokovic Novak",
    number: "3 910",
    country: "SRB",
    isChosen: false,
  },
  {
    id: 8,
    img: pl8,
    name: "Rublev Andrey",
    number: "3 760",
    country: "RUS",
    isChosen: false,
  },
  {
    id: 9,
    img: pl9,
    name: "de Minaur Alex",
    number: "3 745",
    country: "AUS",
    isChosen: false,
  },
  {
    id: 10,
    img: pl10,
    name: "Dimitrov Grigor",
    number: "3 350",
    country: "BUL",
    isChosen: false,
  },
  {
    id: 11,
    img: pl11,
    name: "Tsitsipas Stefanos",
    number: "3 165",
    country: "GRE",
    isChosen: false,
  },
  {
    id: 12,
    img: pl12,
    name: "Paul Tommy",
    number: "3 145",
    country: "USA",
    isChosen: false,
  },
  {
    id: 13,
    img: pl13,
    name: "Rune Holger",
    number: "3 025",
    country: "DEN",
    isChosen: false,
  },
  {
    id: 14,
    img: pl14,
    name: "Humbert",
    number: "2 765",
    country: "FRA",
    isChosen: false,
  },
  {
    id: 15,
    img: pl15,
    name: "Draper Jack",
    number: "2 685",
    country: "GBR",
    isChosen: false,
  },
  {
    id: 16,
    img: pl16,
    name: "Hurkacz Hubert",
    number: "2 640",
    country: "POL",
    isChosen: false,
  },
  {
    id: 17,
    img: pl17,
    name: "Musetti Lorenzo",
    number: "2 600",
    country: "ITA",
    isChosen: false,
  },
  {
    id: 18,
    img: pl18,
    name: "Tiafoe Frances",
    number: "2 585",
    country: "USA",
    isChosen: false,
  },
  {
    id: 19,
    img: pl19,
    name: "Khachanov Karen",
    number: "2 410",
    country: "GBR",
    isChosen: false,
  },
  {
    id: 20,
    img: pl20,
    name: "Fils Arthur",
    number: "2 355",
    country: "FRA",
    isChosen: false,
  },
  {
    id: 21,
    img: pl21,
    name: "Shelton Ben",
    number: "2 330",
    country: "DEN",
    isChosen: false,
  },
  {
    id: 22,
    img: pl22,
    name: "Tabilo Alejandro",
    number: "2 765",
    country: "CHI",
    isChosen: false,
  },
  {
    id: 23,
    img: pl23,
    name: "Korda Sebastian",
    number: "1 985",
    country: "USA",
    isChosen: false,
  },
  {
    id: 24,
    img: pl24,
    name: "Popyrin Alexei",
    number: "1 865",
    country: "AUS",
    isChosen: false,
  },
];

export default data;
