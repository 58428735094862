import React from "react";
import "./Card.css";

const Card = ({ data, fn, isVisible }) => {
  return (
    <div className="bet-card">
      <img
        src={data.img}
        title={data.name}
        alt={data.name}
        width="90"
        height="90"
        className="bet-card__img"
      />
      <p className="t-12 fw-600 text-center">{data.number}</p>
      <p className="t-14 fw-700 upper text-center">{data.name}</p>
      <p className="t-12 fw-500 upper text-center">{data.country}</p>
      <button
        onClick={() => fn(data)}
        className={`t-12 fw-600 bet-card__button ${
          data.isChosen ? "green" : ""
        }`}
        disabled={isVisible || data.isChosen}
      >
        {data.isChosen ? "Toegevoegd" : "Toevoegen"}
      </button>
    </div>
  );
};

export default Card;
