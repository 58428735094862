import React from "react";
import "./Main.css";
import cross from "../../images/main-cross.svg";
import About from "../About/About";
import Play from "../Play/Play";
import News from "../News/News";
import MainPopup from "../Popup/MainPopup";


const Main = () => {
  return (
    <main className="bet-main">
    {/* <MainPopup/> */}
      <section className="bet-main__cover">
        <div className="bet-main__body pad">
          <div className="bet-main__text">
            <img
              src={cross}
              title="cross"
              alt="cross"
              width="130"
              height="50"
              className="bet-main__cross"
            />
            <h1 className="bet-main__header">
              Fantasy <span className="col-green">tennis</span>
            </h1>
            <p className="t-14">
              Dompel jezelf onder in de spannende wereld van sportweddenschappen
              met betroyal-nl - Dit is uw beste keuze in Nederland voor een
              uniek avontuur. We streven ernaar een ruimte te creëren die de
              echte opwinding en opwinding van sportevenementen weerspiegelt.
            </p>
          </div>
        </div>
      </section>
      <About />
      <Play />
      <News />
    </main>
  );
};

export default Main;
