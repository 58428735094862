import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import inst from "../../images/inst.svg";
import telegram from "../../images/telegram.svg";
import age from "../../images/age.svg";

const Footer = () => {
  return (
    <footer className="bet-footer pad">
      <img src={age} title="age 18+" alt="age 18+" className="bet-footer__age"/>
      <div className="bet-footer__links">
        <Link to="/cookies" className="t-12 fw-600">Cookie Beleid</Link>
        <Link to="/privacy" className="t-12 fw-600">Privacybeleid</Link>
        <Link to="/play-responsibly" className="t-12 fw-600">Verantwoord spelen</Link>
      </div>
      <div className="bet-footer__social">
        <Link to="https://www.instagram.com/" target="_blank">
          <img src={inst} title="instagram" alt="instagram" />
        </Link>
        <Link to="https://web.telegram.org/" target="_blank">
          <img src={telegram} title="telegram" alt="telegram" />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
