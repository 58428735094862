import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="bet-privacy pad">
        <h1>Privacybeleid van betroyal-nl.com</h1>
        <p className="t-14">
          Bij betroyal-nl.com, toegankelijk via betroyal-nl.com, is de privacy
          van onze bezoekers een van onze belangrijkste prioriteiten. Dit
          privacybeleiddocument bevat soorten informatie die wordt verzameld en
          vastgelegd door betroyal-nl.com en hoe we deze gebruiken.
          <br></br>Als u nog vragen heeft of meer informatie wenst over ons
          privacybeleid, neem dan gerust contact met ons op.
        </p>
        <h2>Logbestanden</h2>
        <p className="t-14">
          betroyal-nl.com volgt een standaardprocedure voor het gebruik van
          logbestanden. Deze bestanden registreren bezoekers wanneer ze websites
          bezoeken. Alle hostingbedrijven doen dit, evenals sommige analyses van
          hostingdiensten. De informatie die door logbestanden wordt verzameld,
          omvat internetprotocoladressen (IP), browsertype, internetprovider
          (ISP), datum- en tijdstempel, verwijzende/exitpagina's en mogelijk het
          aantal klikken. Deze zijn niet gekoppeld aan persoonsgegevens. Het
          doel van de informatie is het analyseren van trends, het beheren van
          de website, het volgen van de bewegingen van gebruikers op de website
          en het verzamelen van demografische informatie. Ons privacybeleid is
          tot stand gekomen met behulp van de Privacy Policy Generator.
        </p>
        <h2>Onze reclamepartners</h2>
        <p className="t-14">
          Sommige adverteerders op onze website kunnen cookies en webbakens
          gebruiken. Hieronder vindt u onze advertentiepartners. Al onze
          advertentiepartners hebben hun eigen privacybeleid waarin hun beleid
          met betrekking tot gebruikersgegevens wordt geregeld. Om de toegang te
          vergemakkelijken, hebben we hieronder hyperlinks naar hun
          privacybeleid opgenomen.
        </p>
        <h2>Privacybeleid</h2>
        <p className="t-14">
          In deze lijst vindt u het privacybeleid van elke advertentiepartner
          van betroyal-nl.com
          <br></br>Advertentieservers of advertentienetwerken van derden
          gebruiken technologieën zoals cookies, JavaScript of webbakens die
          worden gebruikt in hun respectievelijke advertenties en links die
          verschijnen op betroyal-nl.com en die rechtstreeks naar de browser van
          de gebruiker worden verzonden. In dit geval ontvangen zij automatisch
          uw IP-adres. Deze technologieën worden gebruikt om de effectiviteit
          van hun advertentiecampagnes te meten en/of om de advertentie-inhoud
          die u ziet op de websites die u bezoekt te personaliseren.
          <br></br>Houd er rekening mee dat betroyal-nl.com geen toegang heeft
          tot of controle heeft over deze cookies die door derden worden
          gebruikt.
        </p>
        <h2>Privacybeleid van derden</h2>
        <p className="t-14">
          Het privacybeleid van betroyal-nl.com is niet van toepassing op andere
          adverteerders of websites. Daarom adviseren wij u om het privacybeleid
          van deze advertentieservers van derden te raadplegen voor meer
          gedetailleerde informatie. Het kan hun praktijken en instructies
          bevatten over hoe u zich kunt afmelden voor bepaalde opties.
          <br></br>U kunt cookies uitschakelen via uw individuele browseropties.
          Voor meer gedetailleerde informatie over het cookiebeheer met
          specifieke webbrowsers kunt u deze vinden op de respectievelijke
          websites van de browsers. Wat zijn cookies?
        </p>
        <h2>Informatie voor kinderen</h2>
        <p className="t-14">
          Een ander aandachtspunt van ons werk is het beschermen van kinderen
          bij het gebruik van internet. We moedigen ouders en voogden aan om hun
          online activiteiten te observeren, eraan deel te nemen en/of te
          monitoren en te begeleiden.
          <br></br>betroyal-nl.com verzamelt niet bewust persoonlijke informatie
          van kinderen jonger dan 13 jaar. Als u denkt dat uw kind dit soort
          informatie op onze website heeft verstrekt, raden wij u ten zeerste
          aan onmiddellijk contact met ons op te nemen. Wij zullen ons best doen
          om dergelijke informatie onmiddellijk uit onze administratie te
          verwijderen.
        </p>
        <h2>Alleen online privacybeleid</h2>
        <p className="t-14">
          Dit privacybeleid is alleen van toepassing op onze online activiteiten
          en is geldig voor bezoekers van onze website met betrekking tot de
          informatie die zij hebben gedeeld en/of verzameld op betroyal-nl.com.
          Dit beleid is niet van toepassing op informatie die offline of via
          andere kanalen dan deze website wordt verzameld.
        </p>
        <h2>goedkeuring</h2>
        <p className="t-14">
          Door onze website te gebruiken, gaat u akkoord met ons Privacybeleid
          en gaat u akkoord met de Algemene Voorwaarden.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
