import { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../images/logo.svg";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(false);
  }

  return (
    <header className="bet-header pad">
      <Link to="/" className="bet-header__link">
        <img
          src={logo}
          title="logo"
          alt="logo"
          width="261"
          height="20"
          className="bet-header__logo"
        />
      </Link>
      <div className="bet-header__link-container">
        <Link to="/about-us" className="bet-header__link">
          Over ons
        </Link>
        <Link to="/play-now" className="bet-header__link">
          Speel nu
        </Link>
        <Link to="/news" className="bet-header__link">
          Voorspellingen
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            title="burger menu icon"
            className="bet-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            title="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/about-us" className="bet-header__link">
              Over ons
            </Link>
            <Link to="/play-now" className="bet-header__link">
              Speel nu
            </Link>
            <Link to="/news" className="bet-header__link">
              Voorspellingen
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
