import React, { useState } from "react";
import "./Form.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ContactPopup from "../Popup/ContactPopup";
import closePopup from "../../images/delete-btn.svg";

const Form = ({ fn }) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [isFormSend, setIsFormSend] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
        setFormData({
          name: "",
          email: "",
        });
    setIsFormSend(true);
    setTimeout(() => {
      setIsFormSend(false);
      fn(false);
    }, 2000);
    })
    .catch((error) => {
      console.log("Error sending message");
    });
  };

  return (
    <>
      <section className={`bet-popup ${isFormSend && "hidden"}`}>
        <div className="bet-form">
          <div className="bet-form__top">
            {location.pathname === "/contacts" ? (
              <h1>Contacteer ons</h1>
            ) : (
              <h2>Contacteer ons</h2>
            )}
            <p className="t-14">
              Laat een verzoek achter en onze managers nemen contact met u op
            </p>
            <form onSubmit={handleSubmit} className="bet-form__form">
              <label className="bet-form__label">
                Uw e-mail
                <input
                  type="email"
                  className="bet-form__input"
                  name="email"
                  placeholder="examply@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="bet-form__label">
                Uw naam
                <input
                  type="text"
                  className="bet-form__input"
                  name="name"
                  placeholder="Naam"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>

              <button type="submit" className="bet-form__button  fw-800 ital">
                Versturen
              </button>
            </form>
          </div>
          <div className="bet-form__img"></div>
          <img
            src={closePopup}
            title="close popup"
            alt="close popup"
            className="bet-form__close"
            onClick={() => {
              fn(false);
            }}
          />
        </div>
      </section>
      {isFormSend && <ContactPopup fn={setIsFormSend} />}
    </>
  );
};

export default Form;
